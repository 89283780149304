import FAQ from "../FAQ";
import { Collapse } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const faqData = [
  {
    category: "General",
    items: [
      { question: "What is CureBay Care Sathi?", answer: "CureBay Care Sathi is your 24x7 personal healthcare assistant. It connects you instantly to the right healthcare services—doctor consultations, diagnostics, ambulance support, medicine delivery, and more—with just a call or WhatsApp message." },
      { question: "Who can use Care Sathi services?", answer: "Anyone who subscribes to CureBay Care Sathi can avail the services for themselves and their immediate family members." },
      { question: "How do I reach Care Sathi in case of an emergency?", answer: "Simply call our helpline or send a WhatsApp message. Our team is available 24/7 to provide immediate help." },
      { question: "Is this service available pan-India?", answer: "Currently, Care Sathi services are available in specific locations. Please check with our team to confirm service availability in your area." }
    ]
  },
  {
    category: "Subscription Plans",
    items: [
      { question: "What are the available subscription plans?", answer: "Semi-Annual Plan: ₹495 for 6 Months (₹99/month x 5 months)-One Month Free. Annual Plan: ₹990 for 12 Months (₹99/month x 10 months)- Two Months Free." },
      { question: "What is included in the subscription fee?", answer: "The fee covers unlimited access to Care Sathi assistance for all listed services. However, the actual cost of services like doctor consultations, tests, and medicine will be billed separately at discounted partner rates." },
      { question: "Can I cancel my subscription mid-way?", answer: "Yes, but we do not offer partial refunds for unused months. However, you will continue to receive services until the subscription end date." },
      { question: "Can I upgrade from a 6-month plan to a 12-month plan?", answer: "At the moment, plan upgrades aren’t available. However, you can choose a new plan once your current subscription ends. We're working on more flexible options soon—stay tuned!" }
    ]
  },
  {
    category: "Services & Support",
    items: [
      { question: "What types of doctor consultations are covered?", answer: "We offer general physician and specialist consultations across multiple departments via video or in-person appointments with partner hospitals and clinics." },
      { question: "Can I get tests done at home?", answer: "Yes! We provide hassle-free home sample collection for pathology and radiology tests through accredited diagnostic partners." },
      { question: "How fast can I get an ambulance?", answer: "Our response time varies by location but we aim to dispatch an ambulance within 15-30 minutes in most areas." },
      { question: "Do you assist with hospitalization and surgery planning?", answer: "Absolutely. Our Care Sathi team will help you with pre-admission paperwork, surgery coordination, and hospital selection based on your preferences and medical needs." },
      { question: "Can I get home medical equipment and nursing care?", answer: "Yes. We offer doorstep delivery of medical equipment (like oxygen concentrators, wheelchairs, etc.) and can arrange trained nursing professionals for home care." }
    ]
  },
  {
    category: "Tech & Accessibility",
    items: [
      { question: "What if I can’t type or use my phone properly in an emergency?", answer: "No worries! Use our voice-to-text feature—just speak your request, and Care Sathi will handle the rest. It’s ideal for elders, people with disabilities, or anyone under stress who needs help quickly." },
      { question: "Can I access services through WhatsApp only?", answer: "No. You can reach Care Sathi via phone call or WhatsApp chat—whichever is more convenient for you. We respond instantly on both platforms." },
      { question: "Do I need to download any app?", answer: "Not at all! There’s no app download required. Access Care Sathi services seamlessly via call or WhatsApp—fast, light, and easy." },
      { question: "Is there a fee for using the voice-to-text feature?", answer: "No, it’s absolutely free. The speech-to-text feature is built into our service at no extra charge—because accessibility should never be an add-on." }
    ]
  },
  {
    category: "Billing & Discounts",
    items: [
      { question: "Will I get any discounts on healthcare services?", answer: "As a valued Care Sathi subscriber, you enjoy exclusive rates on doctor consultations, lab tests, medicines, and more—through our trusted healthcare partners." },
      { question: "How will I be billed for services?", answer: "Some services may require pre-payment, while others can be settled post-service. You'll always receive a clear breakdown of costs before confirming any request—no hidden charges, no surprises." },
      { question: "Can I add more family members under my plan?", answer: "Absolutely. Your immediate family (spouse, children, and parents) is covered under your plan. For extended family members, we recommend opting for a separate subscription to ensure they get the same uninterrupted care." }
    ]
  },
  {
    category: "Still Have Questions?",
    items: [
      { question: "How can I speak to a Care Sathi representative right now?", answer: "Click here to [Call Now] or [Chat on WhatsApp]. We're here 24x7 to help you." }
    ]
  }
];

const CustomPanelHeader = ({ headerText }) => (
  <div className="flex gap-4 items-center text-[#003b6f] font-bold">
    <span>{headerText}</span>
  </div>
);

const CustomPanelHeader1 = ({ headerText }) => (
  <div className="flex gap-4 items-center font-semibold">
    <span>{headerText}</span>
  </div>
);

const Faqdcs = () => {
  return (
    <section className="bg-[#f3f8ff] sm:mt-0 mt-20">
      <div className="container mx-auto sm:py-[90px] py-[10px]">
        <h2 className="text-center text-2xl md:text-4xl font-bold text-[#003b6f] leading-normal mb-[50px]">
          Frequently Asked <span className="text-[#28a745]">Questions</span>
        </h2>

        <div className="md:w-[90%] mx-auto">
          <Collapse accordion expandIconPosition="end" className="bg-White border-none" defaultActiveKey={["1"]}>
            {faqData.map((section, index) => (
              <Panel header={<CustomPanelHeader headerText={section.category}  />} key={index + 1} forceRender>
                <Collapse accordion expandIconPosition="end" className="bg-White border-none" defaultActiveKey={index === 0 ? ["0"] : []}>
                  {section.items.map((item, i) => (
                    <Panel header={<CustomPanelHeader1 headerText={item.question} />} key={`${index + 1}-${i}`}>
                      <p className="text-textGray">{item.answer}</p>
                    </Panel>
                  ))}
                </Collapse>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </section>
  );
};

export default Faqdcs;
