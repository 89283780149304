import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { APP_ROUTES } from '../../PageRouting/AppRoutes';
import { ToastContainer, toast } from "react-toastify";
import http from "../../../Redux/Services/http-common";
import Loader from '../../Shared/loader';
import sucessvectoricon from "../../../Assets/Images/sucessvectoricon.svg";
import moment from 'moment';
import { useHistory } from "react-router-dom";


function Dialogue() {
  const [plans, setPlans] = useState([]);

  const [selectedPayMode, setSelectedPayMode] = useState({
    wallet: false,
    card: false,
  });
    const userData = useSelector((state) => state.authReducer.patientData);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;

  console.log(userData, "patientinfofcgvbh")
  const walletinfo = useSelector((state) => state.patientwallet);

  const [IsPaymentTypeModalOpen, setIsPaymentTypeModalOpen] = useState(false);
  const [selectedplan, setSelectedplan] = useState('');
  const [errorDuringPay, setErrorDuringPay] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [loadingDuringPayment, setLoadingDuringPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_NEWBASEURL}GetSubscriptionPlan/planType`);
        const data = await response.json();
        if (data.status === 200) {
          setPlans(data.data);
        }
      } catch (error) {
        console.error("Error fetching subscription plans:", error);
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {

    if (
      localStorage.getItem("paymentStatus") &&
      localStorage.getItem("trancationid")
    ) {
      redirectAfterTxn(localStorage.getItem("trancationid"));
    }
  }, []);

  const handleOk2 = () => {
    setIsPaymentTypeModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsPaymentTypeModalOpen(false);
  };

  const selectedPlanDuration = selectedplan?.durationMonth || 0; // Ensure a valid number
  const startDate = moment();
  const formattedStartdate = moment(startDate).format("YYYY-MM-DD")
  const expiryDate = startDate.clone().add(selectedPlanDuration, "months"); // Add months


  const formattedExpiry = expiryDate.format("YYYY-MM-DD");

 
  console.log(formattedStartdate, "startDate", formattedExpiry, "formattedExpiry")

  const DoPay = async () => {
    setErrorDuringPay("");
    if (!selectedPayMode.wallet && !selectedPayMode.card) {
      toast("Please select payment mode.");
      return;
    }


    if (selectedPayMode.wallet) {
      if (
        selectedplan?.payableAmount >
        walletinfo?.patientwalletinfoData?.balance
      ) {
        toast("Balance is not sufficient.");
        return;
      } else {
        setLoadingDuringPayment(true);

        let payload = {
          patientCode: userData?.code,
          amount: selectedplan?.payableAmount,

        };

        let data = await http.put(
          `${process.env.REACT_APP_BASEURL}api/v1/payments/wallet/transaction`,
          payload
        );

        console.log("qqqqqqqq", data?.data?.data?.transcationId, data.data.statusCode);
        if (data.data.statusCode === 200) {



          localStorage.setItem("redirectUrl", APP_ROUTES.THANKYOUDCS);

          localStorage.setItem(
            "patientCode",
            userData.code
          );

          localStorage.setItem("status", "1");
          localStorage.setItem("sourceChannel", "B2C");

          localStorage.setItem("totalamount", selectedplan?.payableAmount);
          localStorage.setItem("duration",selectedplan?.durationMonth);
          localStorage.setItem("trancationid", data.data.data.transcationId);

          localStorage.setItem("payMode", "CureBay wallet");
          localStorage.setItem("walletstatus", "success");
          localStorage.setItem("email", userData?.email);
          localStorage.setItem("mobile", userData.mobile);
    
          localStorage.setItem("startdate", formattedStartdate);
          localStorage.setItem("expiry", formattedExpiry);

          localStorage.setItem("subscriptionName", selectedplan?.planName);
          localStorage.setItem("subscriptionId", selectedplan?.subscriptionId);
          localStorage.setItem(
            "paymentRemarks",
            "Your Order placed successfully"
          );
          history.push(APP_ROUTES.THANKYOUDCS, { transactionId: data.data.data.transcationId });
         
      
        } else {
          setLoadingDuringPayment(false);
          setErrorDuringPay("Something went wrong try again.");
        }
      }
    }

    if (selectedPayMode.card) {

      localStorage.setItem("redirectUrl", APP_ROUTES.THANKYOUDCS);

      localStorage.setItem("patientCode", JSON.stringify(userData.code));
      // localStorage.setItem("profile", JSON.stringify(profile));
      // localStorage.setItem("status", "1");
      localStorage.setItem("duration",selectedplan?.durationMonth);
      localStorage.setItem("sourceChannel", "B2C");
      // localStorage.setItem("discountPercentage", "0");
      localStorage.setItem("email", userData.email);
      localStorage.setItem("mobile", userData.mobile);
      localStorage.setItem("startdate", formattedStartdate);
      localStorage.setItem("expiry", formattedExpiry);
      localStorage.setItem("payMode", "CC");
      localStorage.setItem("totalamount", selectedplan?.payableAmount);


      localStorage.setItem("subscriptionName", selectedplan?.planName);
      localStorage.setItem("subscriptionId", selectedplan?.subscriptionId);

      console.log(selectedPayMode, "Sbvisdhovhsdobgsobgsuobs");
      const formattedName = userData?.firstName?.replace(/\s+/g, '');
      console.log(formattedName,"edrtgyhju");

      const url =
        process.env.REACT_APP_PAYU_BASEURL +
        `patientId=${userData?.code}&amount=${selectedplan?.payableAmount}&firstname=${formattedName}&email=${userData?.email}&phone=${userData.mobile}&productinfo=subscription&udf1=Patient&service_provider=payu_paisa`;

      window.location.replace(url);
      console.log(url, "url")

    }

  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="loader" />
        <p>Processing your payment...</p>
      </div>
    );
  }

  if (paymentDone) {
    let status = "";
    let amount = JSON.parse(localStorage.getItem("totalamount"));
    let id = localStorage.getItem("trancationid");
    if (id) {
      status = `Thank you! Your order status is successful. Your Transaction ID for this transaction is ${id}.`;
    }
    let time = setTimeout(() => {
      localStorage.removeItem("amount");
      localStorage.removeItem("trancationid");
      clearTimeout(time);
    }, 1000);

    return (
      <div className="min-h-screen flex items-center justify-center bg-lightGray px-4">
        <div className="bg-White shadow-lg rounded-lg p-6 sm:p-10 max-w-lg w-full text-center">
          <img
            className="mx-auto sm:h-28 h-20 w-28 sm:w-32 mb-6"
            src={sucessvectoricon}
            alt="Success"
          />
          <h5 className="text-2xl sm:text-3xl font-extrabold text-green-600 mb-4">
            Subscription Purchase Successful!
          </h5>
          <h6 className="text-lg sm:text-xl font-medium text-[#313131] mb-6">
            Thank you for purchasing the subscription.
          </h6>
          <p className="text-[#313131] text-sm sm:text-base mb-8">
            {id && status}
          </p>
          <button
            onClick={() => {
              history.push("/mymembership");
              window.location.reload();
            }}
            type="button"
            className="w-full sm:w-auto inline-block px-8 py-3 sm:px-10 sm:py-3 rounded-lg bg-primaryColor text-White font-semibold hover:bg-primaryColor focus:outline-none focus:ring-2 focus:ring-primaryColor focus:ring-offset-2"
          >
            Go to subscription
          </button>
        </div>
      </div>
    );
  }

  const formatDateTime = (dateString) => {
    return `${dateString} 00:00:00`;
  };

  const redirectAfterTxn = async (txnid) => {


    setLoading(true);

    try {
      
      const patientCode = JSON.parse(localStorage.getItem("patientCode"));
      const payMode = localStorage.getItem("payMode");
      const totalamount = localStorage.getItem("totalamount");
      const sourceChannel = localStorage.getItem("sourceChannel");
      const email = localStorage.getItem("email");
      const mobile = localStorage.getItem("mobile");
      const startdate = localStorage.getItem("startdate");
      const expiry = localStorage.getItem("expiry");
      const subscriptionId = localStorage.getItem("subscriptionId");
      const subscriptionName = localStorage.getItem("subscriptionName");
      const paymentStatus = localStorage.getItem("paymentStatus");
      const walletStatus = localStorage.getItem("walletstatus");

     
      const formattedStartDate = formatDateTime(startdate);
      const formattedEndDate = formatDateTime(expiry);

     
      let statuscode = 31; 
      if (paymentStatus === "success" || walletStatus === "success") {
        statuscode = 1;
      } else if (paymentStatus === "pending") {
        statuscode = 30;
      }

     
      const payload = {
        subscriptionId,
        planName: subscriptionName,
        patientId: patientCode,
        emailId: email,
        whatsAppNum: mobile,
        startDate: formattedStartDate,  
        endDate: formattedEndDate,      
        amount: parseFloat(totalamount) || 0,
        paymentStatus: "COMPLETED",
        payMode,
        status: statuscode,
        autoRenewal: 1,
        txnId: txnid,
        refTxnId: "REF8768739343",
        sourceChannel,
        cheCenterId: "CENTRALCLINIC",
        cheCenterName: "Central eClinic - Bhubaneswar",
        cheBranchId: "LOC_CENTRALECLINIC",
        cheBranchName: "Bhubaneswar",
      };

     
      const response = await http.post(`${process.env.REACT_APP_BASEURL}PatientSubscription/create`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response?.data, "API Response");

      if (response?.status === 200) {
        setPaymentDone(true);
      } else {
        console.error("API Error:", response?.data);
      }
    } catch (error) {
      console.error("Error in transaction processing:", error);
    } finally {
      setLoading(false);

     
      [
        "patientCode",
        "payMode",
        "totalamount",
        "sourceChannel",
        "email",
        "mobile",
        "redirectUrl",
        "subscriptionId",
        "amount",
        "actualAmount",
        "subscriptionName",
        "paymentStatus",
        "walletstatus",
        "startdate",
        "expiry",
      ].forEach((item) => localStorage.removeItem(item));
    }
  };

  return (
    <>
      <ToastContainer />
      {loadingDuringPayment && <Loader />}
      <div className="flex flex-col items-center max-w-4xl mx-auto z-0">
        <h2 className="text-center text-xl font-bold mb-4">Select a Plan to Subscribe</h2>

        <div className="flex flex-wrap justify-center gap-4 w-full ">
       
          {plans.map((plan) => (
            <div
              key={plan.id}
              className="relative p-4  rounded-lg shadow-md w-80 bg-[#F3F8FF]"
            >

              <h3 className="text-lg font-semibold">{plan.planName}</h3>


              <p className="text-2xl font-bold">
                ₹{plan.payableAmount} <span className="text-sm font-normal">for {plan.durationMonth} months</span>
              </p>


              {plan.description && <p className="text-secondaryColor font-medium text-sm">{plan.description}</p>}


              <button className="mt-3 w-full bg-primaryColor text-White py-2 rounded-md" onClick={() => {
                setIsPaymentTypeModalOpen(true);
                setSelectedplan(plan);
              }
              }>
                Subscribe Now
              </button>

            </div>
          ))}
        </div>
      </div>
      <Modal
        title={
          <p className="font-semibold text-primaryColor text-xl z-10">
            Select Payment Mode
          </p>
        }
        open={IsPaymentTypeModalOpen}
        onOk={handleOk2}
        onCancel={handleCancel2}
        zIndex={2000}
        footer={[]}
        className="w-11/12 md:w-96"
      >
        <div
          className="text-base  text-center mb-4 bg-lightGray cursor-pointer rounded-md p-2"
          style={{
            backgroundColor: "#e5e6e7",
            border: selectedPayMode.wallet ? "2px solid #66B889" : "",
          }}
          onClick={() => setSelectedPayMode({ wallet: true, card: false })}
        >
          CureBay wallet - Rs{" "}
          {walletinfo?.patientwalletinfoData?.balance &&
            walletinfo?.patientwalletinfoData?.balance.toFixed(2)}{" "}
        </div>
        <div
          className="text-base text-center mb-4 bg-lightGray cursor-pointer rounded-md p-2"
          style={{
            backgroundColor: "#e5e6e7",
            border: selectedPayMode.card ? "2px solid #66B889" : "",
          }}
          onClick={() => setSelectedPayMode({ wallet: false, card: true })}
        >
          Debit/Credit Cards/UPI & Others
        </div>
        <Button
          className="bg-secondaryColor text-White w-52 flex mx-auto justify-center"
          size="large"
          onClick={DoPay}
        >
          Pay
        </Button>
      </Modal>
    </>
  );
}

export default Dialogue;


// import React from "react";

// const pricingPlans = [
//   { name: "Standard", price: "$19.99", color: "#E91E63", gradient: "#E91E63, #C2185B" },
//   { name: "Premium", price: "$29.99", color: "#03A9F4", gradient: "#03A9F4, #0288D1" },
//   { name: "Business", price: "$49.99", color: "#FF9800", gradient: "#FF9800, #F57C00" },
// ];

// const PricingCard = ({ name, price, color, gradient }) => {
//   return (
//     <div className="relative w-64 p-6 rounded-lg overflow-hidden shadow-lg" style={{ backgroundColor: color, borderRadius: "15px", clipPath: "polygon(0% 0%, 100% 0%, 100% 75%, 85% 100%, 0% 100%)" }}>
//       <div className="absolute top-0 left-0 w-full h-full opacity-30" style={{ background: `linear-gradient(135deg, ${gradient})` }}></div>
//       <div className="absolute top-3 left-3 px-3 py-1 bg-White rounded-md shadow text-gray-900 font-semibold">
//         {name}
//       </div>
//       <div className="text-white text-3xl font-bold mt-8">{price}</div>
//     </div>
//   );
// };

// const Dialogue = () => {
//   return (
//     <div className="flex justify-center gap-8 py-10 bg-gray-100">
//       {pricingPlans.map((plan) => (
//         <PricingCard key={plan.name} {...plan} />
//       ))}
//     </div>
//   );
// };

// export default Dialogue;

