import HIW1 from "../../../Assets/Images/hiwsaresathi1.svg";
import HIW2 from "../../../Assets/Images/hiwcaresathi2.svg";
import HIW3 from "../../../Assets/Images/labinsightstep3.svg";
import HIW4 from "../../../Assets/Images/hiwcaresathi4.svg";
import Layouts from "../../Layouts/index";
import TrustIcon from "../../../Assets/Images/Icons/Trust Icon.svg";
import TrustIcon1 from "../../../Assets/Images/Icons/TrustIcon1.svg";
import { useApiData } from '../../../Context/ApidtaContext';
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { useEffect, useState } from "react";
import axios from "axios";
import { Carousel } from "primereact/carousel";
import Faqdcs from "./Faqdcs";
import whatapp from "../../../Assets/Images/whatsapp.svg"
import Status from "./Status";
import Slider from "react-slick";
import bannerdcs from "../../../Assets/Images/bannerdcs.png"
import bannerdcs1 from "../../../Assets/Images/bannardcs2.png"
import { Dialog } from 'primereact/dialog';
import Dialogue from "./Dialogue";
import hiw1 from "../../../Assets/Images/dcs/hiw1.svg"
import hiw2 from "../../../Assets/Images/dcs/hiw2.svg"
import hiw3 from "../../../Assets/Images/dcs/hiw3.svg"
import { message } from "antd";

const Dcs = () => {


  const { caresathi } = useApiData();
  console.log(caresathi, "caresathicms")
  const userData = useSelector((state) => state.authReducer.patientData);
  const history = useHistory();
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const storedData = JSON.parse(localStorage.getItem("patientInfo"));
  console.log(storedData?.data?.code, "e4rdtfgyhj")

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("subscription") === "true") {
      setVisible(true);
    }
  }, [location.search]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleCareSathiClick = () => {
    if (Object.keys(userData).length === 0) {
      // history.push("/");
      if (location.pathname === "/") {
        history.push("/", { isLoginModalOpen: true });
      } else {
        history.push("/", { isLoginModalOpen: true });
      }
    } else {
      setVisible(true);
    }
  };

  const [homePageSections, setHomePageSections] = useState([]);

  useEffect(() => {
    fetchHomeContent();
  }, []);

  const fetchHomeContent = async () => {
    try {
      let result = await axios.get(
        `${process.env.REACT_APP_CMS_BASEURL}home-page-v1s/?populate=sections,sections.headerComp,sections.headerComp.button,sections.firstComp,sections.firstComp.button,sections.secondComp,sections.secondComp.button,sections.static,sections.thirdComp,sections.thirdComp.button,sections.forthComp,sections.image_url,sections.static,sections.fifthComp,sections.comp,sections.scomps,sections.sixthComp,sections.sixthComp.button,sections.seventh,sections.seventh.button,sections.eighthComp,sections.eighthComp.eightsubcomp,sections.NinthComp,sections.static,sections.tenthcomp,sections.static,sections.elevencomp,sections.Kavach`
      );

      if (result.data.data.length) {
        console.log(result.data.data[0].attributes.sections, "edrtfgyhu")
        const ninth = result.data.data[0].attributes.sections;
        const data = ninth?.[13]?.NinthComp ? ninth[13].NinthComp.map(item => item) : [];

        console.log(data, "edrtfgyh")
        setHomePageSections(data);
      }
    } catch (error) { }
  };







  const services = [
    { title: "Emergency Services", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/emergency01.png` },
    { title: "Consult Top Doctors", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/doctors-01.png` },
    { title: "Book a Bed in Hospital", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/hospital-bed-01.png` },
    { title: "Medical Equipment Rental", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/first-aid-kit-01.png` },
    { title: "Home Care & Nursing", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/nurse-01.png` },
    { title: "Expert Physio Care", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/ExceptPhysiotherapyPhoto1.png` },
    { title: "AI Based Screening", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/noun-pattern-recognition-01.png` },
    { title: "Medicine Delivery", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/MedicineDeliveryPhoto1.png` },
    { title: "And many more", icon: "+" },
  ];
  const handleCareSathiClick1 = () => {
    if (Object.keys(userData).length === 0) {
      // history.push("/");
      if (location.pathname === "/") {
        history.push("/", { isLoginModalOpen: true });
      } else {
        history.push("/", { isLoginModalOpen: true });
      }
    } else {
      history.push(APP_ROUTES.ASKCARESATHI);
    }
  };
  const handleCallback = async () => {
    const url = `${process.env.REACT_APP_DCS}callback/call_back`
    const requestBody = {
      patientid: storedData?.data?.code,
      // context: 'just testing'
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }


      const data = await response.json();

      message.success(
        ` ${data.message} `
      );
      console.log('API Response:', data);
      // Handle success response
    } catch (error) {
      console.error('API call failed:', error);
      message.error(
        " Something went wrong. Please try again later."
      );
      // Handle error
    }
  };

  const phoneNumber = "+918335000999";
  const handleCallUs = () => {
    if (navigator.userAgent.match(/iPhone|Android/i)) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      navigator.clipboard.writeText(phoneNumber)
        .then(() => {
          message.success(
            "Phone number copied to clipboard!"
          );
         
        })
        .catch(() => {
          message.error(
            "Failed to copy the phone number. Try again"
          );
        });
    }
  };





  return (
    <>
      <Layouts style={{ backgroundColor: "#004171" }} className="relative">
        {/* Title Content */}
        {/* <SubHeader /> */}
        <section className="home-banner min-h-[70vh] my-8  ">
          <div className="container mx-auto hidden sm:block">
            <div className="bg-primaryColor absolute left-0 md:w-[90%] h-[70vh] rounded-e-xl"></div>
            <div className="md:grid grid-cols-2 gap-5 min-h-[70vh] items-center relative">
              <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
                <span className="text-secondaryColor text-sm md:text-6xl font-medium  mb-5 md:mb-3">
                  CareSathi
                </span>
                <div className="flex flex-row">
                  <h1 className="text-primaryColor md:text-White text-2xl md:text-3xl font-bold sm:mt-8 mt-0 ">
                    Welcome to  <span className="text-secondaryColor "> a World of Care  </span>
                    <br />

                    Always by your side

                  </h1>
                </div>
                <p className="text-primaryColor md:text-White font-extralight text-sm md:text-base mt-2 mb-4">

                </p>
                <button
                  className=" rounded-lg px-4 py-2 mr-2 mt-4 bg-secondaryColor"
                  style={{ color: "#ffff" }}
                  onClick={handleCareSathiClick}
                >
                  Subscribe Now
                </button>

                <button
                  className=" rounded-lg px-4 py-2 mr-2 mt-4 bg-secondaryColor"
                  style={{ color: "#ffff" }}
                  onClick={handleCareSathiClick1}
                >
                  Ask CareSathi
                </button>
              </div>

              <div className="hidden sm:block z-1 absolute right-0 bottom-0 z-10">

                <img src={`${process.env.REACT_APP_IMG_BASEURL}b2c-web/DoctorPatientPhoto01.png`} alt="herosection" />
              </div>

            </div>

          </div>

          <div className=" sm:hidden block bg-[#F3F8FF]  p-6 text-center">

            <h2 className="text-lg font-bold text-primaryColor">CareSathi</h2>

            {/* Subtitle */}
            <p className="text-textGray font-medium">
              Welcome to a <span className="font-bold text-[#000]">World of Care</span>
            </p>

            <button
              className=" rounded-lg px-4 py-2 mr-2 mt-4 bg-primaryColor"
              style={{ color: "#ffff" }}
              onClick={handleCareSathiClick}
            >
              Subscribe Now
            </button>



            <button
              className=" rounded-lg px-4 py-2 mr-2 mt-4 bg-secondaryColor"
              style={{ color: "#ffff" }}
              onClick={handleCareSathiClick1}
            >
              Ask CareSathi
            </button>


            <div className="flex justify-center my-4">
              <div className="relative">

                <div className="absolute top-0 left-0 w-full h-full bg-green-500 rounded-full -z-10"></div>
                <img
                  src={`${process.env.REACT_APP_IMG_BASEURL}b2c-web/DoctorPatientPhoto01.png`}
                  alt="herosection"

                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>



          </div>
        </section>


        <section className="bg-[#D9F1E7]">

          <Status />
        </section>

        <section className="mt-10">
          <>
            <div className="hidden sm:block">
              <Slider {...settings}>

                <div className="flex justify-center items-center h-full">
                  <img src={bannerdcs} className="mx-auto" />
                </div>
                <div className="flex justify-center items-center h-full">
                  <img src={bannerdcs1} className="mx-auto" />
                </div>

              </Slider>
            </div>

            <div className="block sm:hidden">
              <Slider {...settings}>

                <div className="flex justify-center items-center h-full">
                  <img src={`${process.env.REACT_APP_IMG_BASEURL}b2c-web/GrandMotherWithBannerPhoto01.png`} className="mx-auto" />
                </div>
                <div className="flex justify-center items-center h-full">
                  <img src={`${process.env.REACT_APP_IMG_BASEURL}b2c-web/LadyDoctorWithBannerPhoto01.png`} className="mx-auto" />
                </div>

              </Slider>
            </div>



          </>
        </section>

        <section className="bg-[#f3f8ff] ">
          <div className="text-center mx-auto px-4 py-10 md:py-20 sm:block hidden">
            <h2 className="text-4xl font-semibold text-[#004171]">
              Our <span className="text-green-600">Services</span>
            </h2>

            <div className="mt-10 flex flex-col items-center space-y-10">

              <div className="grid grid-cols-2 sm:grid-cols-5 gap-10">
                {[
                  { title: "Emergency Services", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/emergency01.png` },
                  { title: "Consult Top Doctors", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/doctors-01.png` },
                  { title: "Book a Bed in Hospital", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/hospital-bed-01.png` },
                  { title: "Medical Equipment Rental", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/first-aid-kit-01.png` },
                  { title: "Home Care & Nursing", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/nurse-01.png` },
                ].map((service, index) => (
                  <div key={index} className="flex flex-col items-center space-y-3">
                    <div className="flex items-center justify-center w-24 h-24 bg-[#D9F1E7] rounded-full shadow-md">
                      <img src={service.icon} alt={service.title} className="w-12 h-12 object-contain" />
                    </div>
                    <p className="text-sm font-medium text-center">{service.title}</p>
                  </div>
                ))}
              </div>



              <div className="grid grid-cols-2 sm:grid-cols-4 gap-6 sm:gap-10">
                {[
                  { title: "Expert Physio Care", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/ExceptPhysiotherapyPhoto1.png` },
                  { title: "AI Based Screening", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/noun-pattern-recognition-01.png` },
                  { title: "Medicine Delivery", icon: `${process.env.REACT_APP_IMG_BASEURL}b2c-web/MedicineDeliveryPhoto1.png` },
                  { title: "And many more", icon: "＋" },
                ].map((service, index) => (
                  <div key={index} className="flex flex-col items-center space-y-3">
                    <div className="flex items-center justify-center w-20 h-20 sm:w-24 sm:h-24 bg-[#D9F1E7] rounded-full shadow-md">
                      {service.icon.startsWith("http") || service.icon.startsWith("/") ? (
                        <img src={service.icon} alt={service.title} className="w-10 h-10 sm:w-12 sm:h-12 object-contain" />
                      ) : (
                        <span className="text-3xl sm:text-5xl ">{service.icon}</span>
                      )}
                    </div>
                    <p className="text-xs sm:text-sm font-medium text-center">{service.title}</p>
                  </div>
                ))}
              </div>

            </div>
          </div>


          <div className="container mx-auto px-4 py-10 md:py-20 block sm:hidden">
            <h2 className="text-center text-2xl md:text-4xl font-semibold text-[#004171]">
              Our <span className="text-green-600">Services</span>
            </h2>

            <div className="mt-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 sm:gap-10 place-items-center">
              {services.map((service, index) => (
                <div key={index} className="flex flex-col items-center space-y-3 text-center">
                  <div className="flex items-center justify-center w-20 h-20 sm:w-24 sm:h-24 bg-[#D9F1E7] rounded-full shadow-md">
                    {service.icon.startsWith("http") || service.icon.startsWith("/") ? (
                      <img src={service.icon} alt={service.title} className="w-10 h-10 sm:w-12 sm:h-12 object-contain" />
                    ) : (
                      <span className="text-3xl sm:text-5xl ">{service.icon}</span>
                    )}
                  </div>
                  <p className="text-xs sm:text-sm font-medium">{service.title}</p>
                </div>
              ))}
            </div>
          </div>


        </section>



        <section className="">
          <div className="container mx-auto px-4 py-10 md:py-20">
            <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-12">
              How it <span className="text-secondaryColor">Works</span> ?
            </h2>

            <div className="flex flex-col sm:flex-row items-center justify-center gap-8 sm:gap-12 md:w-4/5 mx-auto text-center">
              {/* Step 1 */}
              <div className="flex flex-col items-center gap-4 sm:gap-6 w-full sm:w-auto max-w-xs">
                <div className="bg-[#F0F8FF] rounded-full w-14 h-14 sm:w-20 sm:h-20 flex items-center justify-center">
                  <img src={hiw1} alt="Step 1" className="w-10 sm:w-16" />
                </div>
                <div className="flex flex-col items-center">
                  <div className="text-sm sm:text-base font-semibold text-[#42BA85]">STEP 1</div>
                  <div className="text-lg sm:text-xl font-semibold text-[#004171]">Subscribe</div>
                  <p className="text-sm sm:text-base font-medium text-[#676C80]">
                    Subscribe to a plan that fits your needs
                  </p>
                </div>
              </div>

              {/* Step 2 */}
              <div className="flex flex-col items-center gap-4 sm:gap-6 w-full sm:w-auto max-w-xs">
                <div className="bg-[#F0F8FF] rounded-full w-14 h-14 sm:w-20 sm:h-20 flex items-center justify-center">
                  <img src={hiw2} alt="Step 2" className="w-10 sm:w-16" />
                </div>
                <div className="flex flex-col items-center">
                  <div className="text-sm sm:text-base font-semibold text-[#42BA85]">STEP 2</div>
                  <div className="text-lg sm:text-xl font-semibold text-[#004171]">Get a Personal Health Assistant</div>
                  <p className="text-sm sm:text-base font-medium text-[#676C80]">
                    Reach out to your Health Assistant
                  </p>
                </div>
              </div>

              {/* Step 3 */}
              <div className="flex flex-col items-center gap-4 sm:gap-6 w-full sm:w-auto max-w-xs">
                <div className="bg-[#F0F8FF] rounded-full w-14 h-14 sm:w-20 sm:h-20 flex items-center justify-center">
                  <img src={hiw3} alt="Step 3" className="w-10 sm:w-16" />
                </div>
                <div className="flex flex-col items-center">
                  <div className="text-sm sm:text-base font-semibold text-[#42BA85]">STEP 3</div>
                  <div className="text-lg sm:text-xl font-semibold text-[#004171]">Get Doorstep Service</div>
                  <p className="text-sm sm:text-base font-medium text-[#676C80]">
                    Get care at home or visit our network hospitals with priority booking
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>




        <section className=" p-4 sm:p-0 ">
          <div className="relative bg-[#003b6f] py-2 sm:py-12 sm:px-6 text-White rounded-lg sm:rounded-none">

            {/* Half Circle Right Side */}
            <div className="absolute right-0 top-0 h-full w-1/2 block  overflow-hidden z-0 pointer-events-none">
              <div className="bg-[#ffffff1a] rounded-full w-[213px] sm:w-[250px] h-[208px] sm:h-[253px] absolute right-[-150px] top-2/4"></div>
            </div>


            <div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-between px-2 sm:px-4 py-10 md:py-16">
              <div className="w-full md:w-1/2  justify-center mb-6 md:mb-0 sm:flex hidden">
                <img
                  src={`${process.env.REACT_APP_IMG_BASEURL}b2c-web/DoctorPhoto01.png`}
                  alt="Doctor Illustration"
                  className="w-64 sm:w-72 md:w-80"
                />
              </div>
              {/* Right Section - Text & Buttons */}
              <div className="w-full md:w-1/2 text-left space-y-4">
                <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold leading-snug">
                  Get access to <span className="text-secondaryColor">Convenient Hassle-Free</span> Healthcare
                </h2>
                <p className="text-base sm:text-lg text-gray-200">
                  We are always by your side, we listen with heart, respond with care, and stand with you through every step.
                </p>

                {/* Buttons */}
                <div className="mt-6 flex flex-col sm:flex-row items-start gap-4" >
                  <button className="bg-White text-[#003b6f] px-6 py-2 rounded-md font-medium shadow-md w-full sm:w-auto cursor-pointer" onClick={handleCallUs}>
                    Contact Us
                  </button>
                  <button className="border border-White text-White px-6 py-2 rounded-md font-medium w-full sm:w-auto cursor-pointer" onClick={handleCallback}>
                    Request Callback
                  </button>

                  <div className="flex items-center justify-center  text-White px-6 py-2 rounded-md font-medium w-full sm:w-auto text-center cursor-pointer">
                    <a
                      href={`https://wa.me/91${process.env.REACT_APP_WHATSAPP_DCS}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="flex">
                        <img src={whatapp} alt="WhatsApp" className="w-6 h-6" />
                        <span className="ml-2 text-green-400 font-medium">Chat via WhatsApp</span>
                      </div>
                    </a>
                  </div>

                </div>
              </div>

              {/* Left Section - Image */}
              <div className="w-full md:w-1/2  justify-start mb-6 md:mb-0 sm:hidden flex">
                <img
                  src={`${process.env.REACT_APP_IMG_BASEURL}b2c-web/DoctorPhoto01.png`}
                  alt="Doctor Illustration"
                  className="w-[94px] h-[90px]"
                />
              </div>

            </div>
          </div>

        </section>

        <Faqdcs />
      </Layouts>
      <div className="fixed bottom-[10%] sm:bottom-0 left-0 right-0 bg-White py-3 px-4 shadow-md z-50 h-[10%] flex justify-center items-center">
  <button className=" w-full sm:w-[75%] bg-[#003865] text-White text-sm py-2 sm:py-3 px-10 rounded-full "  onClick={handleCareSathiClick}>
    Subscribe Now
  </button>
</div>


      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        position="top"

        header="Subscription Plan"
        draggable={false}
      >
        <Dialogue />
      </Dialog>
    </>

  );
};

export default Dcs;
