import React from "react";

const Status = () => {
  return (

    <div className="flex justify-center items-center py-4 sm:py-16">
    <div className="bg-green-100 px-6  sm:px-20 rounded-lg w-full    grid grid-cols-2 gap-4 sm:grid-cols-4">
      
      {/* Card 1 */}
      <div className="bg-White p-4 sm:p-6 rounded-lg border-l-4 border-primaryColor shadow-sm flex flex-col justify-center">
        <h2 className="text-sm sm:text-xl font-bold text-primaryColor">240+</h2>
        <p className="text-[10px] sm:text-base ">Expert Doctors Onboard</p>
      </div>
  
      {/* Card 2 */}
      <div className="bg-White p-4 sm:p-6 rounded-lg border-l-4 border-primaryColor shadow-sm flex flex-col justify-center">
        <h2 className="text-sm sm:text-xl font-bold text-primaryColor">170+</h2>
        <p className="text-[10px] sm:text-base ">Trusted Hospital Partner</p>
      </div>
  
      {/* Card 3 */}
      <div className="bg-White p-4 sm:p-6 rounded-lg border-l-4 border-primaryColor shadow-sm flex flex-col justify-center">
        <h2 className="text-sm sm:text-xl font-bold text-primaryColor">35+</h2>
        <p className="text-[10px] sm:text-base ">Diagnostic Centres</p>
      </div>
  
      {/* Card 4 */}
      <div className="bg-White p-4 sm:p-6 rounded-lg border-l-4 border-primaryColor shadow-sm flex flex-col justify-center">
        <h2 className="text-sm sm:text-xl font-bold text-primaryColor">150+</h2>
        <p className="text-[10px] sm:text-base ">eClinics</p>
      </div>
  
    </div>

  </div>
  
  
  );
};

export default Status;
