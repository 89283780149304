import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import sucessvectoricon from "../../../Assets/Images/sucessvectoricon.svg";
import http from "../../../Redux/Services/http-common";
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import moment from "moment";

function Thankyoupagedcs() {
  const history = useHistory();
  const location = useLocation();
  const transactionId = location.state?.transactionId;
  const [loading, setLoading] = useState(true);
  const [paymentDone, setPaymentDone] = useState(false);

  console.log(transactionId,"transactionid")

  useEffect(() => {
    const gatewayTransactionId = localStorage.getItem("trancationid");
    console.log(gatewayTransactionId,"gbhnj")
    console.log(localStorage.getItem("paymentStatus") ,"rdfgh")
    if (localStorage.getItem("trancationid")) {
      redirectAfterTxn(localStorage.getItem("trancationid"));
     
    }
  }, []);



  const formatDateTime = (dateString) => `${dateString} 00:00:00`;

  const redirectAfterTxn = async (txnid) => {
    console.log(txnid,"xsdcfvg");
    setLoading(true); 

    try {
      const patientCode = JSON.parse(localStorage.getItem("patientCode"));
      const payMode = localStorage.getItem("payMode");
      const totalamount = localStorage.getItem("totalamount");
      const sourceChannel = localStorage.getItem("sourceChannel");
      const email = localStorage.getItem("email");
      const mobile = localStorage.getItem("mobile");
      const startdate = localStorage.getItem("startdate");
      const expiry = localStorage.getItem("expiry");
      const subscriptionId = localStorage.getItem("subscriptionId");
      const subscriptionName = localStorage.getItem("subscriptionName");
      const paymentStatus = localStorage.getItem("paymentStatus");
      const duration =localStorage.getItem("duration");
      const walletStatus = localStorage.getItem("walletstatus");


        const selectedPlanDuration = duration || 0; 
        const startDate = moment();
        const formattedStartdate = moment(startDate).format("YYYY-MM-DD")
        const expiryDate = startDate.clone().add(selectedPlanDuration, "months"); 
      
      
        const formattedExpiry = expiryDate.format("YYYY-MM-DD");
      const formattedStartDate = formatDateTime(formattedStartdate);
      const formattedEndDate = formatDateTime(formattedExpiry);

      console.log(startDate,expiry,formattedStartDate,formattedEndDate,"rftgyhjn")

      let statuscode = 31;
      if (paymentStatus === "success" || walletStatus === "success") {
        statuscode = 1;
      } else if (paymentStatus === "pending") {
        statuscode = 30;
      }

      const payload = {
        subscriptionId,
        planName: subscriptionName,
        patientId: patientCode,
        emailId: email,
        whatsAppNum: mobile,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        amount: parseFloat(totalamount) || 0,
        paymentStatus: "COMPLETED",
        payMode,
        status: statuscode,
        autoRenewal: 1,
        txnId: txnid,
       
        sourceChannel,
        cheCenterId: "CENTRALCLINIC",
        cheCenterName: "Central eClinic - Bhubaneswar",
        cheBranchId: "LOC_CENTRALECLINIC",
        cheBranchName: "Bhubaneswar",
      };

      console.log(payload,"edrfgthb")

      const response = await http.post(
        `${process.env.REACT_APP_BASEURL}PatientSubscription/create`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response?.status === 200) {
        setPaymentDone(true); // Fix: Ensure payment success state updates
      } else {
        console.error("API Error:", response?.data);
      }
    } catch (error) {
      console.error("Error in transaction processing:", error);
    } finally {
      setLoading(false);

      // Cleanup localStorage
      [
        "patientCode",
        "payMode",
        "totalamount",
        "sourceChannel",
        "email",
        "mobile",
        "redirectUrl",
        "subscriptionId",
        "amount",
        "actualAmount",
        "subscriptionName",
        "paymentStatus",
        "walletstatus",
        "startdate",
        "expiry",
      ].forEach((item) => localStorage.removeItem(item));
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Spin size="large" />
        <p className="mt-2 text-primaryColor font-semibold">Processing your payment...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-lightGray px-4">
      <div className="bg-White shadow-lg rounded-lg p-6 sm:p-10 max-w-lg w-full text-center">
        {paymentDone ? (
          <>
            <img
              className="mx-auto sm:h-28 h-20 w-28 sm:w-32 mb-6"
              src={sucessvectoricon}
              alt="Success"
            />
            <h5 className="text-2xl sm:text-3xl font-extrabold text-green-600 mb-4">
              Subscription Purchase Successful!
            </h5>
            <h6 className="text-lg sm:text-xl font-medium text-[#313131] mb-6">
              Thank you for purchasing the subscription.
            </h6>
            <button
              onClick={() => {
                history.replace("/mymembership"); 
              }}
              type="button"
              className="w-full sm:w-auto inline-block px-8 py-3 sm:px-10 sm:py-3 rounded-lg bg-primaryColor text-White font-semibold hover:bg-primaryColor focus:outline-none focus:ring-2 focus:ring-primaryColor focus:ring-offset-2"
            >
              Go to Membership
            </button>
          </>
        ) : (
          <>
            <h5 className="text-2xl sm:text-3xl font-extrabold text-danger mb-4">
              Payment Failed!
            </h5>
            <h6 className="text-lg sm:text-xl font-medium text-[#313131] mb-6">
              Your payment was not successful. Please try again.
            </h6>
            <button
              onClick={() => history.replace("/services/dcs")}
              type="button"
              className="w-full sm:w-auto inline-block px-8 py-3 sm:px-10 sm:py-3 rounded-lg bg-danger text-White font-semibold hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Try Again
            </button>
             
          </>
        )}
      </div>
    </div>
  );
}

export default Thankyoupagedcs;
