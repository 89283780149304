import React, { } from "react";

import Mobileai from "../../../Assets/Images/mobile_ai.svg";
import Slider from "react-slick";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import { RightOutlined } from "@ant-design/icons";
import "react-toastify/dist/ReactToastify.css";
import QuantumBackgroundImage from '../../../Assets/Images/Home/qantum-homepage-background.png'

const Quantum = ({data}) =>{
    // let result = data.headerComp[0]
    const settings = {
        dots: false,
        // infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // centerMode: true,
        // autoplay: true,
        autoplaySpeed: 3500,
        // centerPadding: "100px",
        prevArrow: <img src={PrevArrow} alt="icons" />,
        nextArrow: <img src={NextArrow} alt="icons" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
        // centerMode: true,
        // centerPadding: "100px"
            //   dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true,
              arrows: false
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              adaptiveHeight: true,
              arrows: false
            },
          },
        ],
      };
return(
    <section className="quantum-homepage-container md:mb-10"> 
    <img src= {QuantumBackgroundImage} className="background-style" />
    <div className="flex sm:flex-col flex-row sm:justify-center py-3 px-2 md:pt-10 md:pb-8 md:pl-24" >
      <div className="sm:px-0 px-4 text-center md:text-left md:pl-8" style={{zIndex: 1}} >  
    <h4 className="text-2xl font-semibold" style={{color:"#ffff"}} ><p className='ai-heading md:mb-3.5' style={{color:"#42BA85"}} >Curebay Quantum</p>Empower Your Health With AI Ecosystem</h4>
    </div>
    <div className="flex sm:justify-start justify-end sm:mt-0 mt-[-30px]">
      
    {/* <img className="hidden sm:block" src= {qantumleft} alt='img'/>
    <img className="block sm:hidden h-[181px] w-[281px]" src={Mobileai} alt="img" /> */}

    </div>
    </div>
    <div className="" >
    <Slider {...settings}>
    <div className="ai-service-info">
        <div className='relative z-10' >
        <img className = "ai-service-image" src = {data?.firstComp[0].image_url} />
        </div>
        <div className="px-2 py-2 z-50 h-[150px] sm:h-[140px]" style={{backgroundColor:"#FFFFFF", borderBottomRightRadius: 21, borderBottomLeftRadius: 12}}>
        <p className="text-xl font-semibold mb-2 mt-3" style={{color:"#004171"}} >{data?.firstComp[0].subHeading}</p>
        <p className="text-base sm:font-normal mb-2" style={{color:"#676C80"}} >{data?.firstComp[0].description}</p>
        <a style={{color:"#42BA85"}} className=" sm:text-base sm:font-semibold mb-4" 
        // href="/care-sathi"
        href="/services/dcs"
         >Know More <RightOutlined className="pl-2" /> </a>
        </div> 
      </div>
      <div className="ai-service-info">
        <div className='relative z-10' >
        <img className = "ai-service-image" src = {data?.firstComp[1].image_url} />
        </div>
        <div className="px-2 py-2 z-50 h-[150px] sm:h-[140px] " style={{backgroundColor:"#FFFFFF", borderBottomRightRadius: 21, borderBottomLeftRadius: 12}}>
        <p className="text-xl font-semibold mb-2 mt-3" style={{color:"#004171"}} >{data?.firstComp[1].subHeading}</p>
        <p className="text-base sm:font-normal mb-2" style={{color:"#676C80"}} >{data?.firstComp[1].description}</p>
        <a style={{color:"#42BA85"}} className=" sm:text-base sm:font-semibold mb-4"  href="/lab-insight" >Know More <RightOutlined className="pl-2" /> </a>
        </div> 
      </div>
      <div className="ai-service-info"  >
      <div className='relative z-10' >
        <img className = "ai-service-image" src = {data?.firstComp[2].image_url} />
        </div>
        <div className="px-2 py-2 z-50 h-[150px] sm:h-[140px]" style={{backgroundColor:"#FFFFFF", borderBottomRightRadius: 21, borderBottomLeftRadius: 12}}>
        <p className="text-xl font-semibold mb-2 mt-3" style={{color:"#004171"}} >{data?.firstComp[2].subHeading}</p>
        <p className="text-base sm:font-normal mb-2" style={{color:"#676C80"}} >{data?.firstComp[2].description}</p>
        <a style={{color:"#42BA85"}} className=" sm:text-base sm:font-semibold mb-4" href="/smart-vitals-detail" >Know More <RightOutlined className="pl-2" /> </a>
        </div> 
      </div>
      <div className="ai-service-info"  >
      <div className='relative z-10' >
        <img className = "ai-service-image" src = {data?.firstComp[3].image_url} />
        </div>
        <div className="px-2 py-2 z-50 h-[150px] sm:h-[140px]" style={{backgroundColor:"#FFFFFF", borderBottomRightRadius: 21, borderBottomLeftRadius: 12}}>
        <p className="text-xl font-semibold mb-2 mt-3" style={{color:"#004171"}} >
          {/* {data?.firstComp[3].subHeading} */}
          Oral Care
          </p>
        <p className="text-base sm:font-normal mb-2" style={{color:"#676C80"}} >{data?.firstComp[3].description}</p>
        <a style={{color:"#42BA85"}} className=" sm:text-base sm:font-semibold mb-4" href="/services/oralcare" >Know More <RightOutlined className="pl-2" /> </a>
        </div> 
      </div>
            </Slider>
            </div>
    {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 px-4 sm:px-0 sm:gap-8 sm:pr-20 ai-service-info-container" >

    </div> */}
  </section>
)
}

export default Quantum



