import React, { useState, useEffect } from "react";
import { useHistory,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../../Layouts";
import { Col, Dropdown, Modal, Pagination, Row, Select } from "antd";
import MemberCard from "../../../../Assets/Images/Membership/singlePlan.jpg";
import { Option } from "antd/es/mentions";
import NodataImage from "../../../../Assets/Images/Icons/nodata.svg";
import { getMymembershipList } from "../../../../Redux/Action/packages";
import moment from "moment";
import http from "../../../../Redux/Services/http-common";
import DropdownButton from "antd/es/dropdown/dropdown-button";
import Membershipcarddownload from "./MemberShipCardDownload";
import SingleplanImage from "../../../../Assets/Images/Membership/singlePlan.png";
import TwinplanImage from "../../../../Assets/Images/Membership/twinPlan.png";
import FamilyplanImage from "../../../../Assets/Images/Membership/familyPlan.png";
import PrimeCureFamily from "../../../../Assets/Images/Membership/primecure_family.jpg";
import PrimeCureSingle from "../../../../Assets/Images/Membership/primecure_single.jpg";
import PrimeCureTwin from "../../../../Assets/Images/Membership/primecure_twin.jpg";
import WellnessFamily from "../../../../Assets/Images/Membership/wellness_family.jpg";
import WellnessSingle from "../../../../Assets/Images/Membership/wellness_single.jpg";
import WellnessTwin from "../../../../Assets/Images/Membership/wellness_twin.jpg";
import WellnessProFamily from "../../../../Assets/Images/Membership/wellnesspro_family.jpg";
import WellnessProSingle from "../../../../Assets/Images/Membership/wellnesspro_single.jpg";
import WellnessProTwin from "../../../../Assets/Images/Membership/wellnesspro_twin.jpg";
import caresathi from "../../../../Assets/Images/dcs/caresathi_subscription_image.png"

import DiabeticImage from "../../../../Assets/Images/Membership/diabetics.jpg";
import Loader from "../../../Shared/loader";



const membershipImages = {
  "PrimeCure_Single": PrimeCureSingle,
  "PrimeCure_Twin": PrimeCureTwin,
  "PrimeCure_Family": PrimeCureFamily,
  "Wellness_Single": WellnessSingle,
  "Wellness_Twin": WellnessTwin,
  "Wellness_Family": WellnessFamily,
  "WellnessPro_Single": WellnessProSingle,
  "WellnessPro_Twin": WellnessProTwin,
  "WellnessPro_Family": WellnessProFamily,
  "DiabetesCare_Diabetes Care": DiabeticImage,
  "subscription":caresathi,
};


const getImageSrc = (productType, membership) => {
  const key = `${productType}_${membership}`;
  console.log("Generated key:", key);
  return membershipImages[key] || caresathi; 
};


function MembershipCard() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  let [list, setList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("Active Member");
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const search = useLocation().search;
  const [purchasedMemberships, setPurchasedMemberships] = useState([]);
  const isRenewal = new URLSearchParams(search).get("renewal");
  const renewalMembershipId = new URLSearchParams(search).get("id");
  // 44025
  const status = [
    // { name: 'Select an option' ,value:''},
    { name: "Active Member", value: "Active" },
    { name: "Inactive Member", value: "Inactive" },
  ];

  const [openMembershipDownloadDialog, setOpenMembershipDownloadDialog] =
    useState(false);
  const [dataForMembershipDialog, setDataForMembershipDialog] = useState({});
  const [membershipDialognew, setMembershipdialognew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const [totalSize, setTotalSize] = useState(0);

  const history = useHistory();

  const pushtoMembership = (user) => {
    history.push("/services/membership#renew");
    // props.toggleBoolean();
  };

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      if(isRenewal== "true"){
        history.push('/', { from: `/mymembership?renewal=${isRenewal}&id=${renewalMembershipId}` , isLoginModalOpen : true });
      }else{
        history.push('/', { from: "/mymembership" , isLoginModalOpen : true });
      }
      
    }
  }, [])

  useEffect(() => {
    setLoading(true);
    dispatch(getMymembershipList(userData.code,pageNumber,pageSize)).then((res) => {
      setLoading(false);
      setList(res);
      const filteredList = res.map(item => ({
        membershipProductType: item.membershipProductType,
        membership: item.membership,
    }));
    
     localStorage.setItem('purchase_membership_list', JSON.stringify(filteredList));
    console.log(filteredList,"yguhyhyu")
      setTotalSize(res[0]?.totalSize || 0);
    });
  }, [pageNumber,pageSize]);
  const handlePaginationChange = (page, size) => {
    console.log(page,size,"tfggh")
    setpageNumber(page);
    setPageSize(size);
  };

  useEffect(() => {
    if (selectedStatus) {
      switch (selectedStatus.value) {
        case "Active":
          callAPI1();

          break;

        case "Inactive":
          callAPI2();

          break;

        default:
          break;
      }
    }
  }, [selectedStatus]);

  const callAPI1 = () => {
    setLoading(true);
    http
      .get(`${process.env.REACT_APP_BASEURL}Patient_Membership/getMembershipList?patientCode=${patientCode}`)

      .then((res) => {
        setLoading(false);
        console.log(res.data, "event");

        setList(res.data);
      })

      .catch((err) => {
        setLoading(false);
        console.log(err, "Something went wrong1");
      });

    console.log("Calling API for Option 1");
  };

  const callAPI2 = () => {
    setLoading(true);
    http
      .get(`${process.env.REACT_APP_BASEURL}Patient_Membership/getMembershipList?patientCode=${patientCode}`)

      .then((res) => {
        setLoading(false);
        console.log(res.data, "event");

        setList(res.data);
      })

      .catch((err) => {
        setLoading(false);
        console.log(err, "Something went wrong1");
      });

    console.log("Calling API for Option 1");
  };

  const handleOptionChange = (value) => {
    const selectedStatus = status.find(option => option.value === value);
    setSelectedStatus(selectedStatus);
  };

  const handleMembershipDownloadDialogClick = (data) => {
    console.log(data, "seoisdouvgsdougvdosuvsd");
    setDataForMembershipDialog(data);
    setOpenMembershipDownloadDialog(true);
  };

  // const membershipImages = {
  //   "PrimeCure": {
  //     "Single": "primecure_single.jpg",
  //     "Twin": "primecure_twin.jpg",
  //     "Family": "primecure_family.jpg",
  //   },
  //   "Wellness": {
  //     "Single": "wellness_single.jpg",
  //     "Twin": "wellness_twin.jpg",
  //     "Family": "wellness_family.jpg",
  //   },
  //   "WellnessPro": {
  //     "Single": "wellnesspro_single.jpg",
  //     "Twin": "wellnesspro_twin.jpg",
  //     "Family": "wellnesspro_family.jpg",
  //   },
  //   "Diabetes Care": {
  //     "Single": "diabetics.jpg",
  //   },
  // };
  
  // const getImageSrc = (productType, membership) => {
  //   return membershipImages[productType]?.[membership] ;
  // };
  

  return (
    <Layouts>
      {loading &&<Loader/>}
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className=" flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                Memberships
              </div>
              {/* <div className="hidden md:block">
                <Select
                  placeholder="Get membership for"
                  className="md:h-12  md:w-96"
                  defaultValue="option1"
                >
                  <Option value="option1">Active Member</Option>
                  <Option value="option2">Inactive Member</Option>
                </Select>
                <Select
                  value={selectedStatus}
                  onChange={handleOptionChange}
                  placeholder={selectedStatus.name}
                  className="md:h-12  md:w-96"
                >
                  {status.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div> */}
            </div>
          </div>
          <div className="md:hidden block mt-5">
            {/* <Select
              placeholder="Get membership for"
              className="h-12 w-full border border-gray rounded-md"
              defaultValue="option1"
            >
              <Option value="option1">Active Member</Option>
              <Option value="option2">Inactive Member</Option>
            </Select> */}
            {/* <Select
              value={selectedStatus}
              onChange={handleOptionChange}
              placeholder={selectedStatus.name}
              size="large"
              className="md:h-12  md:w-96"
            >
              {status.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.name}
                </Option>
              ))}
            </Select> */}
          </div>
          <div className="mt-6">
            <Row gutter={24}>
              {list?.map((user, i) => (
                <Col xs={24} sm={24} md={8}>
                  <div className="w-full rounded-md bg-White m-2">
                    <div className="flex ">
                      <div className="w-[30%] ">
                        {/* <img
                          src={MemberCard}
                          alt="img"
                          className="border-customGray-500 md:h-[200px] w-full rounded-md  shadow-lg"
                        /> */}
                        {/* {user.membershipProductType !== "Diabetes Care" && user.membership === "Single" && (
                          <img
                            src={SingleplanImage}
                            alt="Single Plan"
                            className="border-customGray-500 md:h-[200px] w-full rounded-md shadow-lg"
                          />
                        )}
                        {user.membership === "Twin" && (
                          <img
                            src={TwinplanImage}
                            alt="Twin Plan"
                            className="border-customGray-500 md:h-[200px] w-full rounded-md shadow-lg"
                          />
                        )} */}


                        {/* {user.membership === "Family" && (
                          <img
                            src={FamilyplanImage}
                            alt="Family Plan"
                            className="border-customGray-500 md:h-[200px] w-full rounded-md shadow-lg"
                          />
                        )} */}

                                              
<img
  src={getImageSrc(user?.membershipProductType, user?.membership)}
  alt={`${user?.membershipProductType} ${user?.membership}`}
  className="border-customGray-500 md:h-[200px] w-full rounded-md shadow-lg"
/>


                        {/* {user.membershipProductType === "Diabetes Care" && user.membership === "Single" && (
                          <img
                            src={DiabeticImage}
                            alt="Diabetes Care"
                            className="border-customGray-500 md:h-[200px] w-full rounded-md shadow-lg"
                          />
                        )} */}
                      </div>
                      <div className="w-[70%] p-5 h-full ">
                        <div className="">
                          <p className="text-textGray font-light text-xs">
                            Member Name
                          </p>
                          <h3 className="text-primaryColor text-xs sm:text-sm font-medium ">
                            {user?.patientName && <>{user.patientName}</>}
                            {user?.familyMemberName1 && <>,{user.familyMemberName1}</>}
                            {user?.familyMemberName2 && <>,{user.familyMemberName2}</>}
                            {user?.familyMemberName3 && <>,{user.familyMemberName3}</>}
                            {user?.familyMemberName4 && <>,{user.familyMemberName4}</>}
                            {user?.familyMemberName4 && <>,{user.familyMemberName4}</>}
                            {user?.familyMemberName4 && <>,{user.familyMemberName4}</>}
                            {user?.familyMemberName5 && <>,{user.familyMemberName5}</>}
                            {user?.familyMemberName6 && <>,{user.familyMemberName6}</>}
                          </h3>
                        </div>
                        <div className=" mt-3">
                          <p className="text-textGray font-light text-xs">
                            Membership Name
                          </p>
                          <h3 className="text-primaryColor text-xs sm:text-sm font-medium">
                            {user?.membershipProductType} {user?.membership}
                          </h3>
                        </div>
                        <div className=" mt-3">
                          <p className="text-textGray font-light text-xs">
                            Membership Card Number
                          </p>
                          <h3 className="text-primaryColor text-xs sm:text-sm font-medium">
                            {user?.membershipCard}
                          </h3>
                        </div>
                        <div className=" mt-3">
                          <p className="text-textGray font-light text-xs">
                            Total INR
                          </p>
                          <h3 className="text-primaryColor text-xs sm:text-sm font-medium">
                            {user?.totalAmount}
                          </h3>
                        </div>
                        <div className="flex justify-between mt-3">
                          <div>
                            <p className="text-textGray font-light text-xs">
                              Start Date
                            </p>
                            <h3 className="text-primaryColor text-xs sm:text-sm font-medium">
                              {moment(user?.fromDate).format("DD/MM/YYYY")}
                            </h3>
                          </div>
                          <div className="">
                            <p className="text-textGray font-light text-xs">
                              Expiry Date
                            </p>
                            <h3 className="text-primaryColor text-xs sm:text-sm font-medium">
                              {moment(user?.toDate).format("DD/MM/YYYY")}
                            </h3>
                          </div>

                        </div>
                        <div className="flex  mt-2">
                          <div class="lg:flex justify-center mt-4 ">
                            <div className="flex justify-end ">
                              <button
                                onClick={(e) => {
                                  handleMembershipDownloadDialogClick(
                                    user
                                  );
                                }}
                                className=" text-White p-2 rounded-md mr-2 text-sm font-medium"
                                style={{ background: "#66B889" }}
                              >
                                View/Download
                              </button>
                            </div>
                           {moment(user?.toDate).isBefore(moment()) ? 
                              <div className="flex justify-end ">
                                <button
                                  onClick={(e) => {
                                    pushtoMembership(
                                      user
                                    );
                                  }}

                                  className=" text-White p-2 rounded-md mr-2 text-sm font-medium"
                                  style={{ background: "#66B889" }}
                                >
                                  Renew Membership
                                </button>
                              </div>
                                : null} 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
              {list && list.length === 0 && (
                <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                  <div className="">
                    <img src={NodataImage} alt="image" className="w-[60px]" />
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No membership available!
                  </p>
                </div>
              )}
            </Row>

            { list?.length > 0 &&
          <div className="flex justify-center mt-4">
          <Pagination
            current={pageNumber}
            pageSize={pageSize}
            total={totalSize}
            showSizeChanger={false} 
            onChange={handlePaginationChange}
          />
        </div>
}
          </div>
        </div>

        <Modal
          title={<span style={{ fontWeight: 'bold', fontSize: '24px' }}>Membership card</span>}
          visible={openMembershipDownloadDialog}
          footer={null}
          onCancel={() => setOpenMembershipDownloadDialog(false)}
          width={"60%"}

        >
          <div>

          <div className="flex justify-center mb-4">
          {/* <img
  src={(() => {
    try {
      return require(`../../../../Assets/Images/Membership/${getImageSrc(
        dataForMembershipDialog.membershipProductType,
        dataForMembershipDialog.membership
      )}`);
    } catch (error) {
      console.error("Image not found:", error);
      return ;
    }
  })()}
  alt={`${dataForMembershipDialog.membershipProductType} ${dataForMembershipDialog.membership}`}
  className="border-customGray-500 md:h-[200px] w-auto rounded-md shadow-lg"
/> */}

    </div>


            <Membershipcarddownload data={dataForMembershipDialog} />
          </div>
        </Modal>
      </section>
    </Layouts>
  );
}

export default MembershipCard;
